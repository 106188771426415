<template>
  <v-dialog max-width="400"
            scrollable
            v-model="dialog"
            persistent>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip top color="primary">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
          >
            <v-icon>mdi-tune</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.filter') }}</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>
        <v-icon left>mdi-tune</v-icon>
        Filtrage
        <v-spacer/>
        <v-icon @click="dialog=false">mdi-close</v-icon>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="12">
            <v-select v-model="filter.cityIds"
                      prepend-icon="mdi-filter-outline"
                      multiple chips deletable-chips small-chips dense clearable
                      :items="$store.state.api.cities"
                      item-value="id"
                      item-text="name"
                      label="Wilayas" hide-details></v-select>
          </v-col>
          <v-col cols="12">
            <v-select v-model="filter.regionIds"
                      prepend-icon="mdi-filter-outline"
                      multiple chips deletable-chips small-chips dense clearable
                      :items="$store.state.api.regions"
                      item-value="id"
                      item-text="name"
                      label="Régions" hide-details></v-select>
          </v-col>

          <v-col cols="12">
            <v-select v-model="filter.typeIds"
                      prepend-icon="mdi-filter-outline"
                      multiple chips deletable-chips small-chips dense clearable
                      :items="$store.state.api.salepointTypes"
                      item-value="id"
                      item-text="name"
                      label="Types" hide-details></v-select>
          </v-col>

          <v-col cols="12">
            <v-select v-model="filter.routeIds"
                      prepend-icon="mdi-filter-outline"
                      multiple chips deletable-chips small-chips dense clearable
                      :items="$store.state.api.salepointRoutes"
                      item-value="id"
                      item-text="name"
                      label="Routes" hide-details></v-select>
          </v-col>

          <v-col cols="6">
            <v-checkbox v-model="filter.opusAccount" hide-details dense label="Compte opus"/>
          </v-col>
          <v-col cols="6">
            <v-checkbox v-model="filter.giftyAccount" hide-details dense label="Compte Gifty"/>
          </v-col>

        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary"
               :disabled="isDisabled"
               depressed block @click="handleFilter">
          Recherche
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      filter: {
        opusAccount: true,
        giftyAccount: true,
      }
    }
  },
  methods: {
    handleFilter() {
      this.dialog = false
      this.$emit('filter', this.filter)
    },
  },
  computed: {
    isDisabled() {
      return Object.values(this.filter).every(x => x === null || x === '' || x.length === 0)
    }
  }
}
</script>

<style scoped>

</style>