<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-text-field rounded filled dense
                      v-model="keyword"
                      append-icon="mdi-magnify"
                      single-line hide-details clearable
                      :placeholder="$t('field.search')"/>
      </v-col>
      <v-col cols="8" :class="'text-'+$float">

        <v-chip disabled class="text-capitalize">
          <v-icon small left>mdi-map-outline</v-icon>
          {{ count }} élements
        </v-chip>

        <FilterDialog @filter="$emit('filter',$event)"/>

        <Export link="campaigns/export"/>

        <Refresh @refresh="$emit('refresh')"/>

        <v-btn color="primary"
               text
               @click="$router.push('/databases')">
          <v-icon left>{{ $lang === 'ar' ? 'mdi-arrow-right' : 'mdi-arrow-left' }}</v-icon>
          {{ $t('btn.back') }}
        </v-btn>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import FilterDialog from "@/views/databases/show/components/FilterDialog";

export default {
  props: ['count'],
  components: {FilterDialog},
  data() {
    return {
      keyword: null
    }
  },
  methods: {},
  watch: {
    keyword() {
      this.$emit('search', this.keyword)
    }
  }
}
</script>

<style scoped>

</style>