<template>
  <div>
    <v-container fluid>

      <v-card v-if="isLoading" flat class="rounded-lg mb-4 shadow">
        <v-card-text>
          <v-row>
            <v-col>
              <v-skeleton-loader type="list-item"/>
            </v-col>
            <v-col>
              <v-skeleton-loader type="list-item"/>
            </v-col>
            <v-col></v-col>
            <v-col></v-col>
            <v-col></v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <breadcrumbs v-else :breadcrumbs="breadcrumbs"/>

      <v-overlay :value="overlay"
                 absolute
                 :color="$vuetify.theme.dark ?'secondary':'white'">
        <v-progress-circular
            indeterminate
            size="60"
            color="primary"
        ></v-progress-circular>
      </v-overlay>

      <v-row justify="center">
        <v-col cols="12">

          <v-card class="rounded-lg shadow" flat :loading="isLoading">
            <v-card-title class="d-block">
              <TableHeader :count="total"
                           @refresh="[filter = {} ,showDatabase()]"
                           @filter="[filter = $event,showDatabase()]"
                           @search="[keyword = $event,showDatabase()]"/>
            </v-card-title>

            <v-divider/>

            <v-card-text class="pa-0">

              <v-skeleton-loader v-if="isLoading" type="table"/>

              <div v-else>
                <v-simple-table v-if="salepoints.length">
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th>Image</th>
                      <th>Code</th>
                      <th>Nom</th>
                      <th>Phone</th>
                      <th>Wilaya</th>
                      <th>Région</th>
                      <th>Zone</th>
                      <th>Type</th>
                      <th>Compte OPUS</th>
                      <th>Compte Gifty</th>
                      <th>Points</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item,i) in salepoints" :key="i">
                      <td>
                        <v-avatar v-if="item.image"
                                  size="35"
                                  class="zoom-pointer"
                                  @click="[image = [{src: $fileUrl + item.image}],visible = true]">
                          <v-img :src="$fileUrl+item.image"></v-img>
                        </v-avatar>
                        <v-avatar v-else
                                  size="35"
                                  class="zoom-pointer"
                                  @click="[image = [{src: require('@/assets/avatar.png')}],visible = true]">
                          <v-img :src="require('@/assets/avatar.png')"></v-img>
                        </v-avatar>
                      </td>
                      <td>{{ item.code }}</td>
                      <td>
                        <span class="text-no-wrap">{{ item.name }}</span>
                      </td>
                      <td>{{ item.phone }}</td>
                      <td>{{ item.city ? item.city.name : '-' }}</td>
                      <td>{{ item.region ? item.region.name : '-' }}</td>
                      <td>{{ item.salepoint_zone ? item.salepoint_zone.name : '-' }}</td>
                      <td>{{ item.salepoint_type ? item.salepoint_type.name : '-' }}</td>
                      <td>
                        <v-icon color="success" dense v-if="item.opus_account">mdi-check</v-icon>
                        <v-icon color="red" dense v-else>mdi-close</v-icon>
                      </td>
                      <td>
                        <v-icon color="success" dense v-if="item.gifty_account_id">mdi-check</v-icon>
                        <v-icon color="red" dense v-else>mdi-close</v-icon>
                      </td>
                      <td>
                        <v-chip small color="success">{{ item.points | toCurrency }}</v-chip>
                      </td>
                      <td>
                        <v-btn color="blue" icon @click="showMore(item)">
                          <v-icon>mdi-plus-circle</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <NoResult v-else/>
              </div>

            </v-card-text>

            <v-divider v-if="total > $totalPerPage"/>
            <v-card-actions v-if="total > $totalPerPage">
              <div class="w-100">
                <v-select dense filled
                          hide-details
                          :items="$perPage"
                          v-model="perPage"></v-select>
              </div>
              <v-spacer/>
              <v-pagination total-visible="10"
                            circle
                            v-model="pagination.current"
                            :length="pagination.total"
                            @input="showDatabase"></v-pagination>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>


      <SalepointDialog ref="salepointDialog"/>

      <vue-easy-lightbox escDisabled
                         moveDisabled
                         :visible="visible"
                         :imgs="image"
                         :index="index"
                         @hide="visible = false"></vue-easy-lightbox>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import TableHeader from "@/views/databases/show/components/TableHeader";

export default {
  components: {TableHeader},
  data() {
    return {
      breadcrumbs: null,
      overlay: false,
      isLoading: false,

      salepoints: [],
      total: 0,
      perPage: 10,
      pagination: {
        current: 1,
        total: 0
      },

      filter: null,
      keyword: null,

      /**Lightbox**/
      image: '',
      visible: false,
      index: 0,
    }
  },
  methods: {
    showDatabase() {
      this.$Progress.start()
      this.isLoading = true

      HTTP.get('/databases/' + this.$route.params.id + '/show?page=' + this.pagination.current, {
        params: {
          keyword: this.keyword,
          filter: this.filter,
          perPage: this.perPage,
        }
      }).then(res => {
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;
        this.salepoints = res.data.data.data

        this.breadcrumbs = [
          {to: '/databases', text: 'breadcrumb.databases'},
          {to: '/databases', text: this.salepoints[0].database.name, disabled: true}
        ]

        this.$Progress.finish()
        this.isLoading = false
      }).catch(err => {
        this.$Progress.fail()
        this.$errorMessage = this.$i18n.t('message.catchError')
        this.isLoading = false
        console.log(err)
      })
    },
    showMore(salepoint) {
      this.$refs.salepointDialog.open(salepoint)
    }
  },
  watch: {
    perPage() {
      this.showDatabase()
    }
  },
  created() {
    this.showDatabase()
  },
}
</script>

<style scoped>
</style>